
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { HandoverData, TransferProductList } from '@/types/handover.d'
import { twoDecimalNoZero } from '@/utils/validate'

@Component
export default class extends Vue {
  @Prop() readonly addShow!: boolean;
  private formData: HandoverData = {
    projectId: '',
    formName: '',
    transferProductList: []
  }

  private submitShow = false
  private isEdit: number | null = null
  private rules = {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: 'change' }
    ],
    transferProductList: [
      { required: true, message: '请添加清单明细', trigger: 'change' }
    ]
  }

  private tableRules = {
    productName: [
      { required: true, message: '请输入名称', trigger: 'change' }
    ],
    productModel: [
      { required: true, message: '请输入规格', trigger: 'change' }
    ],
    productNum: [
      { required: true, message: '请输入数量', trigger: 'change' },
      { validator: twoDecimalNoZero, trigger: ['blur', 'change'] }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  // 列表添加
  onAdd () {
    if (this.isEdit !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.formData.transferProductList.push({
        productName: '',
        productModel: '',
        productUnit: '',
        productNum: '',
        productBak: '',
        isInput: true
      })
      this.isEdit = this.formData.transferProductList.length - 1
    }
  }

  // 保存当条
  onSave (row: TransferProductList, index: number) {
    ;(this.$refs.addTable as ElForm).validate((valid) => {
      if (valid) {
        row.isInput = false
        this.$set(this.formData.transferProductList, index, this.formData.transferProductList[index]) // 重新渲染表格数据
        this.isEdit = null
      }
    })
  }

  // 列表编辑当条
  onEdit (row: TransferProductList, index: number) {
    if (this.isEdit !== null) {
      this.$message.warning('请先保存！')
    } else {
      row.isInput = true
      this.isEdit = index
      this.$set(this.formData.transferProductList, index, this.formData.transferProductList[index]) // 重新渲染表格数据
    }
  }

  // 删除当条
  onDelete (index: number) {
    if (this.isEdit !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formData.transferProductList.splice(index, 1)
        this.$message.success('删除成功')
      })
    }
  }

  onSubmit () {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        if (this.isEdit !== null) {
          this.$message.warning('请先保存！')
        } else {
          this.formData.transferProductList.forEach(item => {
            delete item.isInput
          })
          this.submitShow = true
          this.$axios.post(this.$apis.handover.insertTransferProduct, {
            ...this.formData
          }).then(() => {
            this.$message.success('添加成功')
            this.closeAdd()
          }).catch(() => {
            this.formData.transferProductList.forEach(item => {
              item.isInput = false
            })
          }).finally(() => {
            this.submitShow = false
          })
        }
      }
    })
  }

  // 关闭
  closeAdd () {
    this.$emit('closeAdd')
    this.formData = {
      projectId: '',
      formName: '',
      transferProductList: []
    }
    ;(this.$refs.formData as ElForm).clearValidate()
  }
}
